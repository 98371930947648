import { Alert, AlertIcon, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";

import data from "../utils/custom.geo.json";
import { UserExplorer } from "./UsersExplorer";
import { WorldMap } from "./WorldMap";

export type GeoJsonType = typeof data;
export type FeatureType = GeoJsonType["features"][0];

export const Explore = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const { pathname } = useLocation();
    const isUsersPage = matchPath("/explore/users", pathname);

    useEffect(() => {
        if (isUsersPage) {
            setTabIndex(1);
        } else {
            setTabIndex(0);
        }
    }, [isUsersPage]);
    return (
        <Tabs colorScheme='green' variant='soft-rounded' size='sm' index={tabIndex}>
            <Alert status='warning' justifyContent='center' borderRadius='xl'>
                <AlertIcon />
                This section is under construction
            </Alert>
            <TabList mt='.5rem'>
                <Tab as={Link} to='/explore'>
                    World map
                </Tab>
                <Tab as={Link} to='/explore/users'>
                    Users
                </Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <WorldMap />
                </TabPanel>
                <TabPanel>
                    <UserExplorer />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};
