import { Box, Button, Checkbox, CheckboxGroup, FormControl, FormErrorMessage, HStack, Image, Input, Text, VStack, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../../assets/images/logo.png";
import { useCountrySelect } from "../../../hooks/useCountrySelect";
import { useRegisterUserMutation } from "../api/authApi";
import { AuthLayout } from "../components/AuthLayout";

type RegisterInputs = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    companyName: string;
    vatNumber: string;
    address: string;
    country: number;
    position: string;
    contactNumber: string;
    isDeveloper: boolean;
    isInvestor: boolean;
};

export const Register = () => {
    const navigate = useNavigate();
    const { CountrySelect, value } = useCountrySelect();
    const [registerUser, { isLoading }] = useRegisterUserMutation();
    const toast = useToast();
    const [isAgreed, setIsAgreed] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors }
    } = useForm<RegisterInputs>();

    const onRegister: SubmitHandler<RegisterInputs> = async data => {
        try {
            if (!data.isDeveloper && !data.isInvestor) {
                setError("isDeveloper", {
                    message: "At least one of the checkboxes should be checked"
                });
                return;
            }
            if (!isAgreed) {
                toast({
                    title: "Error!",
                    description: "You should agree with Terms & Conditions",
                    status: "error",
                    duration: 5000
                });
                return;
            }
            await registerUser({
                company: {
                    name: data.companyName,
                    vat: data.vatNumber,
                    country: data.country,
                    is_developer: data.isDeveloper,
                    is_investor: data.isInvestor,
                    address: data.address
                },
                email: data.email,
                first_name: data.firstName,
                last_name: data.lastName,
                password1: data.password,
                position: data.position,
                contact_number: data.contactNumber
            }).unwrap();
            navigate("/email-verification", { state: { email: data.email } });
        } catch (err: any) {
            if (err?.data) {
                const errors: [string, string[]][] = Object.entries(err.data);

                errors.forEach(([key, value]) => {
                    if (key === "non_field_errors") {
                        toast({
                            title: "Error!",
                            description: value[0],
                            status: "error",
                            duration: 5000
                        });
                    } else {
                        if (["first_name", "last_name", "email", "password1", "vat"].includes(key)) {
                            const keysName = {
                                first_name: "firstName",
                                last_name: "lastName",
                                password1: "password",
                                email: "email",
                                vat: "vatNumber"
                            };

                            //@ts-ignore
                            setError(keysName[key], {
                                message: value[0]
                            });
                        }
                        if (key === "company") {
                            //@ts-ignore
                            if (value["vat"]) {
                                setError("vatNumber", {
                                    //@ts-ignore
                                    message: value["vat"][0]
                                });
                            }
                        }
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (value) {
            setValue("country", value);
        }
    }, [value, setValue]);

    return (
        <AuthLayout screen='register'>
            <HStack spacing='3' mb='4'>
                <Image src={logo} alt='logo' w='4rem' />
                <Box fontSize='xs' bgColor='green.400' px='0.5rem' borderRadius='0.5rem'>
                    <Text color='white'>Beta</Text>
                </Box>
            </HStack>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit(onRegister);
                }}
                style={{ display: "flex", flexDirection: "column", gap: ".7rem" }}>
                <HStack spacing='3'>
                    <FormControl isInvalid={Boolean(errors.firstName)}>
                        <Input
                            id='firstName'
                            placeholder='First name'
                            {...register("firstName", {
                                required: "This field is required"
                            })}
                        />
                        <FormErrorMessage>{errors.firstName && errors.firstName.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.lastName)}>
                        <Input
                            id='lastName'
                            placeholder='Last name'
                            {...register("lastName", {
                                required: "This field is required"
                            })}
                        />
                        <FormErrorMessage>{errors.lastName && errors.lastName.message}</FormErrorMessage>
                    </FormControl>
                </HStack>
                <FormControl isInvalid={Boolean(errors.email)}>
                    <Input
                        id='email'
                        placeholder='Email'
                        {...register("email", {
                            required: "This field is required",
                            minLength: {
                                value: 4,
                                message: "Minimum length should be 4"
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                            }
                        })}
                    />
                    <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.password)}>
                    <Input
                        id='password'
                        placeholder='Password'
                        type='password'
                        {...register("password", {
                            required: "This field is required"
                        })}
                    />
                    <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.companyName)}>
                    <Input
                        id='companyName'
                        placeholder="Company's name"
                        {...register("companyName", {
                            required: "This field is required"
                        })}
                    />
                    <FormErrorMessage>{errors.companyName && errors.companyName.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.position)}>
                    <Input
                        id='position'
                        placeholder='Job title'
                        {...register("position", {
                            required: "This field is required"
                        })}
                    />
                    <FormErrorMessage>{errors.companyName && errors.companyName.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.vatNumber)}>
                    <Input
                        id='vatNumber'
                        placeholder='VAT number'
                        type='number'
                        {...register("vatNumber", {
                            required: "This field is required"
                        })}
                    />
                    <FormErrorMessage>{errors.vatNumber && errors.vatNumber.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.address)}>
                    <Input
                        id='address'
                        placeholder="Company's address"
                        {...register("address", {
                            required: "This field is required"
                        })}
                    />
                    <FormErrorMessage>{errors.address && errors.address.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.country)}>
                    {CountrySelect}
                    <FormErrorMessage>{errors.country && errors.country.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.contactNumber)}>
                    <Input
                        id='contactNumber'
                        placeholder='Contact number'
                        type='number'
                        {...register("contactNumber", {
                            required: "This field is required"
                        })}
                    />
                    <FormErrorMessage>{errors.contactNumber && errors.contactNumber.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.isDeveloper || errors.isInvestor)}>
                    <CheckboxGroup colorScheme='green'>
                        <VStack w='full' alignItems='flex-start'>
                            <Checkbox id='isDeveloper' {...register("isDeveloper")}>
                                I am a developer
                            </Checkbox>
                            <Checkbox id='isInvestor' {...register("isInvestor")}>
                                I am an investor
                            </Checkbox>
                        </VStack>
                    </CheckboxGroup>
                    <FormErrorMessage>{errors.isDeveloper && errors.isDeveloper.message}</FormErrorMessage>
                </FormControl>

                <CheckboxGroup colorScheme='green'>
                    <Checkbox isChecked={isAgreed} onChange={e => setIsAgreed(e.target.checked)} mt='6'>
                        <Link to='/terms-conditions' target='_blank'>
                            <Text color='green.500' fontWeight='semibold'>
                                I agree with Terms & Conditions
                            </Text>
                        </Link>
                    </Checkbox>
                </CheckboxGroup>

                <Button type='submit' w='full' mt='4' colorScheme='green' onClick={handleSubmit(onRegister)} isLoading={isLoading}>
                    Sign up
                </Button>
            </form>
        </AuthLayout>
    );
};
