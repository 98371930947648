import { Spinner, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { PageLayout } from "../../../components/PageLayout";
import { useGetAllNewsQuery } from "../api/newsApi";
import { NewsType } from "../api/types";
import { NewsCard } from "../components/NewsCard";

export const News = () => {
    const [limit, setLimit] = useState(5);
    const [page, setPage] = useState(1);
    const [currentNews, setCurrentNews] = useState<NewsType[]>([]);
    const {
        data: allNews,
        isLoading: loadingNews,
        isFetching
    } = useGetAllNewsQuery({
        offset: (page - 1) * limit
    });

    useEffect(() => {
        if (allNews?.results) {
            setLimit(allNews.results.length);
            setCurrentNews(prevNews => [...prevNews, ...allNews.results]);
        }
    }, [allNews]);

    const onNextPage = () => {
        if (allNews?.next) {
            setPage(page + 1);
        }
    };

    return (
        <PageLayout title='News' isLoading={loadingNews} onEndReached={onNextPage}>
            <VStack spacing='2rem' w='full' alignItems='flex-start' bg='white' p='1rem'>
                {currentNews.map(news => (
                    <NewsCard key={news.id} news={news} />
                ))}
                {isFetching && <Spinner alignSelf='center' color='green.500' />}
            </VStack>
        </PageLayout>
    );
};
