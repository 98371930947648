import { HamburgerIcon } from "@chakra-ui/icons";
import {
    Box,
    Circle,
    Flex,
    HStack,
    Hide,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuOptionGroup,
    Show,
    Spinner,
    Text,
    VStack,
    useDisclosure,
    useTheme
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, matchPath, useLocation, useOutlet } from "react-router-dom";

import { DashboardIcon } from "../assets/icons/dashboard";
import { ExitIcon } from "../assets/icons/exit";
import { FolderIcon } from "../assets/icons/folder";
import { ProfileIcon } from "../assets/icons/profile";
import { ProjectsIcon } from "../assets/icons/projects";
import { SearchIcon } from "../assets/icons/search";
import { UsersIcon } from "../assets/icons/users";
import logo from "../assets/images/logo.png";
import { useSocketDisplayMessages } from "../hooks/useSocketDisplayMessages";
import { useWindowEvent } from "../hooks/useWindowEvent";
import { setUserId } from "../modules/auth/data/authSlice";
import { useAuthentication } from "../modules/auth/hooks/useAuthentication";
import { useGetDialogsQuery } from "../modules/chat/api/chatApi";
import { useGetUserDetailsQuery } from "../modules/home/api/usersApi";
import { ProfileUpdateModal } from "../modules/users/components/ProfileUpdateModal";
import { useGetSocketConnectionQuery } from "../modules/websocket/api/socketApi";
import { socketSelector } from "../modules/websocket/data/socketSlice";

export const HomeLayout = () => {
    const dispatch = useDispatch();
    const { connectionStatus } = useSelector(socketSelector);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isAuthenticated, logout, loadingVerification, resetApp } = useAuthentication();
    const { data: dialogs } = useGetDialogsQuery();
    const {
        data: user,
        isLoading: loadingProfile,
        isError
    } = useGetUserDetailsQuery(undefined, {
        skip: !isAuthenticated
    });
    useGetSocketConnectionQuery(undefined, {
        skip: !isAuthenticated || loadingVerification
    });
    useSocketDisplayMessages();
    useWindowEvent();

    const { colors, breakpoints } = useTheme();
    const { pathname } = useLocation();
    const outlet = useOutlet();

    const selectedColor = colors.gray[700];
    const unselectedColor = colors.gray[500];

    const navOptions = [
        {
            isSelected: matchPath("/dashboard", pathname),
            icon: <DashboardIcon color={matchPath("/dashboard", pathname) ? selectedColor : unselectedColor} />,
            to: "/dashboard",
            name: "Dashboard"
        },
        {
            isSelected: matchPath("/projects", pathname),
            icon: <ProjectsIcon color={matchPath("/projects", pathname) ? selectedColor : unselectedColor} />,
            to: "/projects",
            name: "Opportunities"
        },
        // {
        //     isSelected: matchPath("/portfolio", pathname),
        //     icon: <ChartIcon color={matchPath("/portfolio", pathname) ? selectedColor : unselectedColor} />,
        //     to: "/portfolio",
        //     name: "Portfolio"
        // },
        {
            isSelected: matchPath("/my-hub", pathname),
            icon: <FolderIcon color={matchPath("/my-hub", pathname) ? selectedColor : unselectedColor} />,
            to: "/my-hub",
            name: "My hub"
        },
        {
            isSelected: matchPath("/explore", pathname),
            icon: <SearchIcon color={matchPath("/profile", pathname) ? selectedColor : unselectedColor} />,
            to: "/explore",
            name: "Explore"
        },
        {
            isSelected: matchPath("/users", pathname),
            icon: <UsersIcon color={matchPath("/users", pathname) ? selectedColor : unselectedColor} />,
            name: "Chat",
            to: "/users",
            badge: dialogs?.results?.map(dialog => dialog.unread_messages).reduce((a, b) => a + b, 0)
        }
    ];

    useEffect(() => {
        if (isError) {
            resetApp();
        }
    }, [isError, resetApp]);

    //Remove the chat widget
    const chatWidget = document.getElementById("roboassist-widget-container");

    useEffect(() => {
        if (chatWidget) {
            chatWidget.style.display = "none";
        }
    }, []);

    useEffect(() => {
        if (user) {
            dispatch(setUserId(user.id));

            if (!user.is_profile_complete) {
                const store = localStorage.getItem("remindLater");
                if (!store) {
                    onOpen();
                } else {
                    const { expiry } = JSON.parse(store);
                    if (new Date().getTime() > expiry) {
                        localStorage.removeItem("remindLater");
                        onOpen();
                    }
                }
            }
        }
    }, [user, dispatch, onOpen]);

    if (loadingProfile || loadingVerification) {
        return (
            <Box h='100vh' w='full' display='flex' alignItems='center' justifyContent='center'>
                <Spinner />
            </Box>
        );
    }

    return (
        <Flex bg='gray.100' alignItems='flex-start' direction={{ base: "column", sm: "row" }}>
            {process.env.NODE_ENV === "production" && (
                <Helmet>
                    <script>
                        window.aichatbotApiKey="b3fe3eee-81ea-493d-8417-76d25ac186fc";
                        window.aichatbotProviderId="f9e9c5e4-6d1a-4b8c-8d3f-3f9e9c5e46d1";
                    </script>
                    <script id='b3fe3eee-81ea-493d-8417-76d25ac186fc' defer></script>
                </Helmet>
            )}
            <Hide breakpoint={`(max-width: ${breakpoints.sm})`}>
                <VStack px='3' py='6' h='100vh' bg='white' spacing='10'>
                    <Image src={logo} alt='logo' w='2.5rem' />
                    <VStack flexGrow='1' justifyContent='space-between'>
                        <VStack spacing='3'>
                            {navOptions.map(({ icon, to, badge, name, isSelected }, index) => (
                                <Link key={index} to={to} style={{ width: "100%" }}>
                                    <Box p='2' bg={isSelected ? "gray.100" : "transparent"} borderRadius='lg' transition='.5s' position='relative'>
                                        <VStack>
                                            {icon}
                                            <Text fontSize='.6rem' color={isSelected ? "black" : "gray.500"}>
                                                {name}
                                            </Text>
                                            {Boolean(badge) && (
                                                <Circle bg='salmon' color='white' size='1rem' position='absolute' top='5px' right='5px' p='5px'>
                                                    <Text fontSize='xs'>{badge}</Text>
                                                </Circle>
                                            )}
                                        </VStack>
                                    </Box>
                                </Link>
                            ))}
                        </VStack>
                        <VStack spacing='3'>
                            <IconButton
                                aria-label='profile'
                                icon={<ProfileIcon color='black' size={20} />}
                                bg='gray.200'
                                rounded={"full"}
                                colorScheme='none'
                                mb='4'
                                as={Link}
                                to='/profile'
                            />
                            <IconButton aria-label='logout-user' icon={<ExitIcon color='salmon' />} colorScheme='none' onClick={logout} />
                            <Box px='1' py='.5' borderRadius='lg' bg={connectionStatus === "connected" ? "green.200" : "red.200"}>
                                <Text color={connectionStatus === "connected" ? "green.800" : "red.800"} fontSize='xs'>
                                    {connectionStatus}
                                </Text>
                            </Box>
                        </VStack>
                    </VStack>
                </VStack>
            </Hide>
            <Show breakpoint={`(max-width: ${breakpoints.sm})`}>
                <HStack p='4' justifyContent='space-between' bg='white' w='full' position='sticky' top='0' zIndex='5'>
                    <Image src={logo} alt='logo' w='2rem' />
                    <Menu>
                        <MenuButton as={IconButton} aria-label='Options' icon={<HamburgerIcon />} variant='outline' />
                        <MenuList gap='1rem' p='4'>
                            <MenuOptionGroup>
                                {navOptions.map(({ icon, to, badge, name, isSelected }, index) => (
                                    <MenuItem
                                        as={Link}
                                        icon={icon}
                                        key={index}
                                        to={to}
                                        mb='1rem'
                                        bg={isSelected ? "gray.100" : "transparent"}
                                        borderRadius='lg'>
                                        {name}
                                    </MenuItem>
                                ))}
                            </MenuOptionGroup>
                            <MenuDivider />
                            <MenuOptionGroup>
                                <MenuItem
                                    icon={<ProfileIcon color='black' size={20} />}
                                    as={Link}
                                    to='/profile'
                                    bg={matchPath("/profile", pathname) ? "gray.100" : "transparent"}
                                    borderRadius='lg'>
                                    Profile
                                </MenuItem>
                                <MenuDivider />

                                <MenuItem icon={<ExitIcon color='salmon' />} onClick={logout} color='salmon'>
                                    Logout
                                </MenuItem>
                            </MenuOptionGroup>
                        </MenuList>
                    </Menu>
                </HStack>
            </Show>
            <Box p='4' h='100vh' maxW='80rem' mx='auto' flexGrow='1' overflow='auto' w='100vw'>
                {outlet}
            </Box>
            <ProfileUpdateModal isOpen={isOpen} onClose={onClose} />
        </Flex>
    );
};
