import { api } from "../../../api/api";
import { UserResponse } from "./types";

const LIST_USERS_TAG = "listUsers";

export const usersApi = api.enhanceEndpoints({ addTagTypes: [LIST_USERS_TAG] }).injectEndpoints({
    endpoints: build => ({
        getAllUsers: build.query<UserResponse, { search?: string; country?: number[] }>({
            query: ({ search, country }) => ({
                method: "GET",
                url: "users/list",
                params: { search, country }
            }),
            providesTags: [LIST_USERS_TAG]
        })
    })
});

export const { useGetAllUsersQuery } = usersApi;
