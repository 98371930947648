import { Avatar, Box, Button, Circle, HStack, IconButton, Input, InputGroup, InputRightElement, Spinner, Text, VStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { SendIcon } from "../../../assets/icons/send";
import { SearchBar } from "../../../components/Searchbar";
import { useAppDispatch } from "../../../store";
import { getFormattedChatDate, getTimeFromDate } from "../../../utils/date";
import { useGetDialogsQuery } from "../../chat/api/chatApi";
import { TypingAnim } from "../../chat/components/TypingAnim";
import { chatSelector, resetMessages } from "../../chat/data/chatSlice";
import { useGetUserDetailsQuery } from "../../home/api/usersApi";
import { MessageTypes, sendToWs, ws } from "../../websocket/api/socketApi";
import { socketSelector } from "../../websocket/data/socketSlice";

export const UsersList = () => {
    const dispatch = useAppDispatch();
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [userSearch, setUserSearch] = useState("");
    // const { data: users, isLoading } = useGetAllUsersQuery();
    const [userIsTyping, setUserIsTyping] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const { dialogId } = useParams<{ dialogId: string }>();
    const { data: dialogs, isLoading: loadingDialogs } = useGetDialogsQuery();
    const { isLoading: loadingMessages, messages, nextPage, currentUser, isTyping, currentUserName } = useSelector(chatSelector);

    const { connectionStatus } = useSelector(socketSelector);
    const { data: user } = useGetUserDetailsQuery();

    const [msgText, setMsgText] = useState("");

    // Get first messages batch from dialog
    useEffect(() => {
        if (dialogId && messages.length === 0 && currentUser === null) {
            sendToWs({
                msg_type: MessageTypes.GetMessageList,
                dialog_id: dialogId,
                page: "0"
            });
        }
    }, [dialogId, messages, currentUser, connectionStatus]);

    useEffect(() => {
        if (!dialogId) {
            dispatch(resetMessages());
        }
    }, [dialogId, dispatch]);

    const sendMsgText = () => {
        if (!msgText) return;
        sendToWs(
            {
                msg_type: MessageTypes.TextMessage,
                msg: msgText,
                dialog_id: String(dialogId)
            },
            false
        );
        setMsgText("");
        setTimeout(() => {
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTo({
                    top: chatContainerRef.current.scrollHeight,
                    behavior: "smooth"
                });
            }
        }, 400);
    };

    useEffect(() => {
        if (chatContainerRef.current && nextPage === 1) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    }, [messages, nextPage]);

    // fetch next page of messages on scroll to top
    useEffect(() => {
        const handleScroll = (e: any) => {
            if (chatContainerRef.current?.scrollTop === 0 && nextPage !== null && messages.length > 0) {
                sendToWs({
                    msg_type: MessageTypes.GetMessageList,
                    dialog_id: String(dialogId),
                    page: String(nextPage)
                });
            }
            setScrollPosition(e["target"].offsetHeight); //keep current scroll position
        };
        chatContainerRef.current?.addEventListener("scroll", handleScroll);
        return () => {
            chatContainerRef.current?.removeEventListener("scroll", handleScroll);
        };
    }, [nextPage, messages, dialogId, scrollPosition]);

    // send typing status
    useEffect(() => {
        if (!currentUser) return;
        if (userIsTyping) {
            sendToWs(
                {
                    msg_type: MessageTypes.IsTyping,
                    recipient: String(currentUser)
                },
                false
            );
        } else {
            sendToWs(
                {
                    msg_type: MessageTypes.TypingStopped,
                    recipient: String(currentUser)
                },
                false
            );
        }
    }, [currentUser, userIsTyping]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setUserIsTyping(false);
        }, 800);

        return () => {
            clearTimeout(timer);
            setUserIsTyping(true);
        };
    }, [msgText]);

    useEffect(() => {
        if (!messages.length || loadingMessages || !dialogId) return;
        const markAllMessagesHasRead = () => {
            for (const message of messages) {
                if (!message.is_me && !message.read) {
                    const readMessages = JSON.stringify({
                        msg_type: MessageTypes.MessageRead,
                        msg_id: message.id,
                        dialog_id: dialogId
                    });
                    ws.send(readMessages);
                }
            }
        };
        markAllMessagesHasRead();
        const pos = chatContainerRef.current?.scrollHeight;
        setScrollPosition(pos || 0);
    }, [messages, dialogId, loadingMessages]);

    useEffect(() => {
        chatContainerRef.current?.scrollTo({
            top: scrollPosition,
            behavior: "auto"
        });
    }, [scrollPosition]);

    if (loadingDialogs) return <Spinner />;
    if (!dialogs) return <h3>No users available</h3>;

    const orderedMessages = [...messages].reverse();

    return (
        <HStack h='full'>
            <VStack w='18rem' h='full' spacing='.4rem'>
                <SearchBar placeholder='Search for users' value={userSearch} onChange={e => setUserSearch(e.target.value)} />
                <Box p='3' bg='white' borderRadius='lg' w='100%' flexGrow='1' overflowY='auto' display='flex' flexDirection='column' gap='.5rem'>
                    {dialogs.results.map(dialog => (
                        <Link to={"/users/" + String(dialog.id)} key={dialog.id}>
                            <HStack p='3' bg='gray.100' borderRadius='lg' gap='2'>
                                <Avatar src={dialog.recipient?.profile?.avatar} size='sm' />
                                <VStack w='full' gap='0' alignItems='flex-start'>
                                    <HStack alignItems='center'>
                                        <h3>
                                            {dialog.recipient.first_name} {dialog.recipient.last_name}
                                        </h3>
                                        {dialog.recipient.is_online && <Circle size='.5rem' bg='green.300' />}
                                        {dialog.unread_messages && currentUser !== dialog.recipient.id && (
                                            <Box
                                                bg='green.400'
                                                w='1.1rem'
                                                h='1.1rem'
                                                borderRadius='full'
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='center'
                                                position='relative'>
                                                <Text fontSize='xs' color='white' position='absolute'>
                                                    {dialog.unread_messages}
                                                </Text>
                                            </Box>
                                        )}
                                    </HStack>
                                    <p>{dialog.recipient.position}</p>
                                </VStack>
                            </HStack>
                        </Link>
                    ))}
                </Box>
            </VStack>
            <Box p='3' bg='white' borderRadius='lg' h='full' w='full'>
                {dialogId ? (
                    <VStack height='full'>
                        <Text fontWeight='bold'>Conversation with {currentUserName}</Text>
                        <VStack ref={chatContainerRef} bg='gray.100' h='full' w='full' borderRadius='lg' overflow='auto' p='1rem' spacing='3'>
                            {loadingMessages && (
                                <Box w='full' h='full' alignItems='center' justifyContent='center' display='flex'>
                                    <Spinner />
                                </Box>
                            )}
                            {orderedMessages.map((message, index) => {
                                const { id, is_me, created, text, nbo } = message;
                                const previousMessage = orderedMessages?.[index - 1];

                                const previousDate = previousMessage?.created && getFormattedChatDate(new Date(previousMessage.created));

                                const date = getFormattedChatDate(new Date(created));

                                const textContainsUrl = text.match(/(https?:\/\/[^\s]+)/g);
                                const textWithHyperlink = textContainsUrl
                                    ? text.replace(
                                          textContainsUrl[0],
                                          `<a href="${textContainsUrl[0]}" target="_blank" style="color:blue" rel="noopener noreferrer">${textContainsUrl[0]}</a>`
                                      )
                                    : text;
                                const formattedText = <Box textStyle='p' fontSize='sm' dangerouslySetInnerHTML={{ __html: textWithHyperlink }}></Box>;

                                const alignment = is_me ? "flex-end" : "flex-start";

                                if (nbo) {
                                    const isAccepted = nbo.status === "accepted";
                                    const isRejected = nbo.status === "rejected";
                                    return (
                                        <VStack alignSelf={alignment} key={message.id}>
                                            <Box
                                                p='5'
                                                borderWidth='1px'
                                                borderRadius='lg'
                                                bg={isAccepted ? "green.100" : isRejected ? "red.100" : "white"}
                                                shadow='lg'>
                                                <Text>{text}</Text>
                                                <HStack alignItems='center' justifyContent='space-between' mt='1rem'>
                                                    <Button variant='link' as={Link} to={`/projects/${nbo.project.slug}`} size='sm'>
                                                        Go to project details
                                                    </Button>
                                                    {nbo.user !== user?.id && nbo.status === "pending" && (
                                                        <HStack spacing='.5rem' mt='.5rem' justifyContent={"flex-end"}>
                                                            <Button
                                                                variant='outline'
                                                                size='xs'
                                                                onClick={() => {
                                                                    sendToWs({
                                                                        msg_type: MessageTypes.SetNBOStatus,
                                                                        status: "accepted",
                                                                        nbo_id: String(nbo.id),
                                                                        project_slug: nbo.project.slug
                                                                    });
                                                                }}>
                                                                Accept
                                                            </Button>
                                                            <Button
                                                                variant='outline'
                                                                size='xs'
                                                                colorScheme='red'
                                                                onClick={() => {
                                                                    sendToWs({
                                                                        msg_type: MessageTypes.SetNBOStatus,
                                                                        status: "rejected",
                                                                        nbo_id: String(nbo.id),
                                                                        project_slug: nbo.project.slug
                                                                    });
                                                                }}>
                                                                Reject
                                                            </Button>
                                                        </HStack>
                                                    )}
                                                </HStack>
                                            </Box>
                                            <Box textStyle='span' fontSize='xx-small' alignSelf={alignment}>
                                                {getTimeFromDate(new Date(created))}
                                            </Box>
                                        </VStack>
                                    );
                                }

                                return (
                                    <>
                                        {previousDate !== date && (
                                            <Box shadow='md' alignSelf='center' position='sticky' top='0' bg='white' borderRadius='lg' py='3px'>
                                                <Text fontWeight='600' zIndex={index + 1} minW='10rem' textAlign='center' fontSize='sm'>
                                                    {date}
                                                </Text>
                                            </Box>
                                        )}
                                        <VStack key={id} alignSelf={alignment} spacing='1' alignItems={alignment}>
                                            <Box
                                                px='2'
                                                py='1'
                                                bg={is_me ? "gray.200" : "green.200"}
                                                borderRadius='xl'
                                                borderBottomRightRadius={is_me ? "0" : undefined}
                                                borderBottomLeftRadius={is_me ? undefined : "0"}>
                                                {formattedText}
                                            </Box>
                                            <Box textStyle='span' fontSize='xx-small'>
                                                {getTimeFromDate(new Date(created))}
                                            </Box>
                                        </VStack>
                                    </>
                                );
                            })}
                            {isTyping && (
                                <Box bg='green.200' borderRadius='xl' borderBottomLeftRadius='0' alignSelf='flex-start'>
                                    <TypingAnim />
                                </Box>
                            )}
                        </VStack>

                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                sendMsgText();
                            }}
                            style={{ width: "100%" }}>
                            <InputGroup>
                                <Input placeholder='Your message' value={msgText} onChange={e => setMsgText(e.target.value)} />
                                <InputRightElement>
                                    <IconButton aria-label='send-message' icon={<SendIcon />} colorScheme='none' onClick={sendMsgText} />
                                </InputRightElement>
                            </InputGroup>
                        </form>
                    </VStack>
                ) : (
                    <Box h='full' w='full' display='flex' alignItems='center' justifyContent='center'>
                        <Button as={Link} to='/explore/users'>
                            Explore new users
                        </Button>
                    </Box>
                )}
            </Box>
        </HStack>
    );
};
