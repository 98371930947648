import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { LightningIcon } from "../../../assets/svg/lightning";
import { SunIcon } from "../../../assets/svg/sun";
import { WindIcon } from "../../../assets/svg/wind";
import { getCountryFlag } from "../../../utils/flags";
import { useGetCountryPreviewQuery } from "../api/exploreApi";
import { FeatureType } from "../screen/Explore";
import { HorizontalBar } from "./charts/HorizontalBar";
import { PieChart } from "./charts/Pie";

type CountryPreviewProps = {
    selectedCountry: FeatureType | null;
};

export const CountryPreview = ({ selectedCountry }: CountryPreviewProps) => {
    const countryCode = selectedCountry?.properties.iso_a2_eh;
    const { data, isLoading } = useGetCountryPreviewQuery({ countryCode: countryCode || "" }, { skip: !selectedCountry });
    if (!selectedCountry) return null;

    if (isLoading) return <Text>Loading</Text>;

    if (!selectedCountry) return null;

    const { electricity_from_wind } = data || { electricity_from_wind: 0.0 };

    const maxWindEnergy = electricity_from_wind > 500 ? electricity_from_wind + 100 : 500;
    const percentageWindEnergy = (electricity_from_wind / maxWindEnergy) * 100 + "%";

    const barsData = [
        {
            label: "Hours of sunshine: 3.200h",
            min: "0h",
            max: "4.800h",
            percentage: (3200 / 4800) * 100 + "%",
            icon: <SunIcon />
        },
        {
            label: `Wind energy: ${data?.wind_energy.toFixed(2)}%`,
            min: "0%",
            max: "100%",
            percentage: data?.wind_energy + "%",
            icon: <WindIcon />
        },
        {
            label: `Electricity from wind: ${electricity_from_wind.toFixed(2)} TWh`,
            min: "0",
            max: `${maxWindEnergy}TWh`,
            percentage: percentageWindEnergy,
            icon: <LightningIcon />
        }
    ];

    return (
        <VStack w='full' h='full' justifyContent='space-between' p='1rem'>
            <VStack w='full'>
                <HStack w='full' alignItems='flex-start' borderBottomWidth='1px' borderColor='gray.300' pb='2rem'>
                    <VStack flexGrow='1' gap='.5rem' color='gray.600'>
                        <Text fontWeight='bold' fontSize='1.8rem' p='0' w='full'>
                            {selectedCountry.properties.name}
                        </Text>
                        <Text p='0' w='full' fontSize='small'>
                            Capital: {data?.country.capital}
                        </Text>
                        <Text p='0' w='full' fontSize='small'>
                            Size: {data?.country.area.toLocaleString()} km²
                        </Text>
                    </VStack>

                    <Box
                        w='13rem'
                        h='8.2rem'
                        backgroundSize='cover'
                        backgroundPosition='center'
                        backgroundImage={`url(${getCountryFlag(selectedCountry.properties.iso_a2_eh)})`}
                        borderRadius='xl'
                        borderWidth='1px'
                        borderColor='black'
                    />
                </HStack>
                <VStack w='full' overflowY='auto'>
                    <HStack h='20rem' mb='2rem'>
                        <PieChart labels={["83% Renewable Energy", "17% Non-renewable Energy"]} data={[83, 17]} labelPosition='bottom' />
                    </HStack>
                    <VStack w='full' gap='2rem'>
                        {barsData.map(item => (
                            <VStack w='full' key={item.label}>
                                <HStack gap='.5rem' justifyContent='flex-start' w='full' fontSize='sm'>
                                    {item.icon}
                                    <Text>{item.label}</Text>
                                </HStack>
                                <HorizontalBar value={item.percentage} min={item.min} max={item.max} />
                            </VStack>
                        ))}
                    </VStack>
                </VStack>
            </VStack>
            <Button as={Link} w='full' to={selectedCountry.properties.iso_a2_eh}>
                See more
            </Button>
        </VStack>
    );
};
