import { Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logo.png";
import { NewsType } from "../../news/api/types";

type LandingNewsCardProps = {
    news: NewsType;
};

export const LandingNewsCard = ({ news }: LandingNewsCardProps) => {
    return (
        <Link to={news.url} target='blank'>
            <HStack>
                <VStack alignItems='flex-start' flex='1'>
                    <Text fontWeight='semibold'>{news.title}</Text>
                    <HStack fontSize='sm'>
                        <Text>{news.source}</Text>
                        <Text> | </Text>
                        <Text>{getRelativeTime(new Date(news.published_at))}</Text>
                    </HStack>
                </VStack>
                {news.image_url ? (
                    <Image src={news.image_url} alt={news.title} w='6rem' h='5rem' objectFit='cover' borderRadius='xl' />
                ) : (
                    <Center w='6rem' h='5rem' bgColor='gray.100' borderRadius='xl'>
                        <Image src={logo} alt='logo' w='2rem' />
                    </Center>
                )}
            </HStack>
        </Link>
    );
};

const getRelativeTime = (date: Date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const minutes = Math.floor(diff / 60000);
    if (minutes < 60) {
        return `${minutes} minutes ago`;
    }
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return `${hours} hours ago`;
    }
    const days = Math.floor(hours / 24);
    return `${days} days ago`;
};

