import { Box, Button, HStack, Image, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack, useTheme } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useMultiCountrySelect } from "../../../hooks/useCountrySelect";
import { getCountryFlag } from "../../../utils/flags";
import { useGetAllUsersQuery } from "../../users/api/usersApi";
import { MessageTypes, sendToWs } from "../../websocket/api/socketApi";

export const UserExplorer = () => {
    const { colors } = useTheme();
    const [search, setSearch] = useState("");
    const [text, setText] = useState("");
    const { CountrySelect, values } = useMultiCountrySelect();
    const { data: users } = useGetAllUsersQuery({ search, country: values });

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSearch(text);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [text]);

    const startChat = (userId: string) => {
        sendToWs({
            msg_type: MessageTypes.DialogCreated,
            recipient_id: userId
        });
    };

    return (
        <VStack spacing='4' align='stretch' w='full'>
            <HStack spacing='2' w='full'>
                <Input placeholder='Search users...' bgColor='white' rounded='lg' maxW='30rem' value={text} onChange={e => setText(e.target.value)} />
                <Box bgColor='white' rounded='lg'>
                    {CountrySelect}
                </Box>
            </HStack>
            <TableContainer w='full' fontSize='sm'>
                <Table variant='simple' backgroundColor={colors.gray[50]} rounded='xl'>
                    <Thead bg={colors.gray[200]}>
                        <Tr rounded='xl'>
                            <Th>Name</Th>
                            <Th>Company</Th>
                            <Th>Job Title</Th>
                            <Th>Role</Th>
                            <Th>E-mail</Th>
                            <Th>Country</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users?.results.map(user => (
                            <Tr key={user.id}>
                                <Td>
                                    <Text fontWeight='bold'>
                                        {user.first_name} {user.last_name}
                                    </Text>
                                </Td>
                                <Td>{user.company.name}</Td>
                                <Td>{user.position}</Td>
                                <Td>
                                    <HStack alignItems='center'>
                                        {user.company.is_developer && <RoleBadge role='developer' />}
                                        {user.company.is_investor && <RoleBadge role='investor' />}
                                    </HStack>
                                </Td>
                                <Td>{user.email}</Td>
                                <Td>
                                    <HStack alignItems='center'>
                                        <Image
                                            w='1.2rem'
                                            src={getCountryFlag(user.company.country.code)}
                                            alt={user.company.country.name}
                                            borderRadius='lg'
                                        />
                                        <p>{user.company.country.name}</p>
                                    </HStack>
                                </Td>
                                <Td>
                                    <HStack alignItems='center' spacing='.5rem'>
                                        <Button size='xs' onClick={() => startChat(String(user.id))}>
                                            Chat
                                        </Button>
                                        <Button variant='outline' size='xs' onClick={() => null}>
                                            Profile
                                        </Button>
                                    </HStack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </VStack>
    );
};

const RoleBadge = ({ role }: { role: "investor" | "developer" }) => {
    return (
        <Text
            px='2'
            py='.1rem'
            rounded='full'
            fontSize='xs'
            bg={role === "developer" ? "green.200" : "blue.200"}
            color={role === "developer" ? "green.800" : "blue.800"}>
            {role}
        </Text>
    );
};

