import { Box, Center, Grid, Spinner } from "@chakra-ui/react";

import { useGetAllNewsQuery } from "../../news/api/newsApi";
import { Header } from "../components/Header";
import { LandingNewsCard } from "../components/LandingNewsCard";
import { MaxWidthContainer } from "./Landing";

export const LandingNews = () => {
    const { data: allNews, isLoading: loadingNews } = useGetAllNewsQuery({
        offset: 1
    });
    return (
        <Box>
            <Header />
            <MaxWidthContainer>
                {loadingNews ? (
                    <Center height='80vh'>
                        <Spinner />
                    </Center>
                ) : (
                    <Grid
                        m='1rem'
                        templateColumns={{ base: "repeat(1, minmax(0, 1fr))", md: "repeat(2, minmax(0, 1fr))" }}
                        gap='2rem'
                        mt='2rem'
                        columnGap='2.5rem'>
                        {allNews?.results.map(news => <LandingNewsCard key={news.id} news={news} />)}
                    </Grid>
                )}
            </MaxWidthContainer>
        </Box>
    );
};

